export const HomeViewUrl = "/";

export const TrainingViewUrl = "/training-view";
export const ExerciseViewUrl = "/exercise-view";
export const TrainingAddUrl = "/training-add";
export const TrainingStatisticUrl = "/training-statistic";

export const FoodViewUrl = "/food-view";
export const FoodAddUrl = "/food-add";
export const CalculateIngredientsUrl = "/food-statistic";
export const FoodProductUrl = "/food-product";

export const ShoppingViewUrl = "/shopping-view";

export const WeeklyViewUrl = "/weekly-view";
export const DailyViewUrl = "/daily-view";

export const LoginUrl = "/login-panel"

export const FinanceViewUrl = "/finance-view";
export const FinanceConfigUrl = "/finance-config";
